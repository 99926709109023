<template>
  <houses-module-component />
</template>

<script>
import HousesModuleComponent from "@/components/admin/modules/HousesModuleComponent";

export default {
  name: "HousesModule",
  title: "Gestión de Casas | Baja California Health Tourism",
  components: { 
    HousesModuleComponent 
  },
};
</script>

<style scoped>
</style>
