<template>
    <v-tooltip top v-if="user.roles == 'ADMIN' || user.roles == 'COPARMEX'">
        <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="loadQrCode">
                mdi-qrcode
            </v-icon>
        </template>
        <span>Coepris QR</span>

        <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" v-model="dialog" persistent width="90%">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-toolbar-title class="text-subtitle-1 text-md-h5">Administrador de QR</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn class="hidden-sm-and-down" icon dark @click="dialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-container fill-height bottom-gradient fluid class="ma-0">
                    <v-row>
                        <v-col col="6">
                            <div class="fill-height">
                                <!--v-model="model"-->
                                <v-slide-group center-active class="pa-0"
                                    next-icon="mdi-arrow-right-drop-circle-outline"
                                    prev-icon="mdi-arrow-left-drop-circle-outline" show-arrows>
                                    <v-slide-item key="qr" v-slot="{ active, toggle }" v-if="coepris_qr_url">
                                        <v-card :color="active ? undefined : 'white'" class="ma-4 rounded-lg"
                                            height="auto" width="250" elevation="5" @click="toggle">
                                            <v-img
                                                :src="coepris_qr_url != null && coepris_qr_url != '' ? coepris_qr_url : require('@/assets/images/no-image.jpg')"
                                                :lazy-src="require('@/assets/images/no-image.jpg')"
                                                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" aspect-ratio="1"
                                                height="250"
                                                class="rounded-lg white--text align-end justify-start text-end elevation-10">
                                                <v-btn dark icon elevation="0" class="ma-2"
                                                    v-on:click="deleteImageQRAction">
                                                    <v-icon>mdi-delete</v-icon>
                                                </v-btn>

                                                <template v-slot:placeholder>
                                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                                        <v-progress-circular indeterminate
                                                            color="grey lighten-5"></v-progress-circular>
                                                    </v-row>
                                                </template>
                                            </v-img>
                                        </v-card>
                                    </v-slide-item>
                                </v-slide-group>

                                <div class="mt-3">
                                    <v-text-field label="Link del QR" dense v-model="coepris_qr_link"></v-text-field>
                                </div>
                                <div>
                                    <v-file-input v-model="file" small-chips accept="image/png, image/jpeg, image/bmp"
                                        show-size clearable label="Agregar QR">
                                    </v-file-input>
                                </div>
                            </div>
                        </v-col>

                        <v-col col="6">
                            <div>
                                <h4>Vencimiento del QR</h4>
                                <v-date-picker v-model="expire_date_qr" locale="es" scrollable></v-date-picker>
                            </div>

                            <h3 class="mt-2">
                                {{ expire_date_qr }}
                            </h3>
                        </v-col>
                    </v-row>

                    <v-col col="12">
                        <div style="display: flex; justify-content: center">
                            <v-btn @click="saveData" :loading="saveChanges">
                                Guardar datos
                            </v-btn>
                        </div>
                    </v-col>
                </v-container>
            </v-card>
        </v-dialog>
    </v-tooltip>
</template>
<script>
import houseServices from "@/providers/HousesService";

export default {
    name: "QRCoeprisHouse",
    props: ["item"],
    data(){
        return {
            dialog: false,
            valid: false,
            loading: false,
            saveChanges: false,
            file: null,
            expire_date_qr: "",
            coepris_qr_link: "", 
            coepris_qr_url: "",
            user: {},
        }
    },
    methods: {
        loadQrCode(){
            this.dialog = true;
            this.loadData();
        },
        loadData(){
            this.loading = true;
            houseServices.getRecord(this.item.id).then(resp => {
                this.expire_date_qr = resp.value.expire_date_qr;
                this.coepris_qr_link = resp.value.coepris_qr_link == null || resp.value.coepris_qr_link == 'null' ? "" : resp.value.coepris_qr_link;
                this.coepris_qr_url = resp.value.coepris_qr_url;
            }).catch(err => console.log(err)).finally(() => {
                this.loading = false;
            });
        },
        saveData(){
            this.saveChanges = true;
            houseServices.addCoeprisQR(this.item.id, this.file, this.expire_date_qr, this.coepris_qr_link).then(resp=>{
                if(resp.data.success){
                    this.file = null;
                    this.loadData();
                }
            }).catch(err=>console.log(err)).finally(()=>{
                this.saveChanges = false;
            })
        },
        deleteImageQRAction(){
            houseServices.deleteCoeprisQR(this.item.id).then(resp=>{
                if(resp.data.success){
                    this.loadData();
                }
            }).catch(err=>console.log(err));
        }
    },
    mounted(){
        this.user = this.$store.getters.getUser;
    }
}
</script>